import React, { useState } from 'react';
import AppIcon from './AppIcon';
import '../App.css';

function AppIcons(props) {
  const isMobileOrTablet = window.matchMedia("(max-width: 1024px)").matches;
  const [category, setCategory] = useState('all_apps');
  
  return (
    <div className='icons-contanier layout-column center-center'>
      <div className={`categories-contaner layout-row center-${isMobileOrTablet === true ? 'center' : 'left'}`}>
        <div key='all_apps' className={`category white layout-column center-center ${category === 'all_apps' ? 'selected' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              props.eventLog({'ga': 'download', 'fb': 'ViewContent', 'tt': 'ClickToDownload'}, 'all_apps');
              setCategory('all_apps');
              let appDivs = document.getElementsByClassName('app-icon');
              for (let el = 0; el < appDivs.length; el++) {
                appDivs[el].className = appDivs[el].className.replace(' hidden', '');
              }
            }}>
              All apps
        </div>
        {props.categories.map((cat, i) => (
          <div key={cat.replaceAll(' ', '_').toLowerCase()} className={`category white layout-column center-center ${category === cat.replaceAll(' ', '_').toLowerCase() ? 'selected' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            props.eventLog({'ga': 'download', 'fb': 'ViewContent', 'tt': 'ClickToDownload'}, cat.replaceAll(' ', '_').toLowerCase());
            setCategory(cat.replaceAll(' ', '_').toLowerCase());
            let appDivs = document.getElementsByClassName('app-icon');
            for (let el = 0; el < appDivs.length; el++) {
              appDivs[el].className = appDivs[el].className.replace(' hidden', '');
              appDivs[el].className = appDivs[el].className + ' hidden';
              props.apps.forEach(app => {
                if (app.name === appDivs[el].title) {
                  if (app.categories.indexOf(cat) !== -1) {
                    appDivs[el].className = appDivs[el].className.replace(' hidden', '');
                  }
                  return;
                }
              });
            }
          }}>
            {cat}
          </div>
        ))}
      </div>
      <div className={`app-icons layout-row center-${isMobileOrTablet === true ? 'center' : 'left'}`}>
        {props.apps.map((app) => (
            < AppIcon key={app.name.replaceAll(' ', '')} app={app} eventLog={props.eventLog}/>
          ))}
          {/* {Object.keys(props.chunks).map((i, chunk) => (
            <div key={i} className='app-icons layout-row center-center'>
              {props.chunks[i].map((app) => (
                < AppIcon key={app.name.replaceAll(' ', '')} app={app} eventLog={props.eventLog}/>
              ))}
            </div>
          ))} */}
      </div>
    </div>
  );
}

export default AppIcons;
