import React, { useState, useEffect, useRef } from 'react';
// import GaPixel from './utils/ga/GaPixel';
import { trackMetaPixelEvent } from './utils/meta/MetaPixel';
import { trackTikTokPixelEvent } from './utils/tiktok/TiktokPixel';
import Header from './components/Header';
import HomePage from './components/HomePage';
import KlaviyoPopup from './components/KlaviyoPopup';
// import CookieConsent from './components/CookieConsent';
import CookiePage from './components/CookiePage';
import PrivacyPage from './components/PrivacyPage';
import TermsPage from './components/TermsPage';
import AccessibilityPage from './components/AccessibilityPage';
import Footer from './components/Footer';
import Todo from './components/Todo';
import './App.css';
import { Routes, Route } from 'react-router-dom';

function App() {
  // const [showCookies, setShowCookies] = useState(JSON.parse(localStorage.getItem('be_consent')) ? false : true);
  const [showKlaviyo, setShowKlaviyo] = useState(false);
  // const [height, setHeight] = useState(0);
  // const [marginBottom, setMarginBottom] = useState(0);

  const mainRef = useRef(null);
  // const ref = useRef(null);

  // Media query to detect mobile or tablet
  const isMobileOrTablet = window.matchMedia("(max-width: 1024px)").matches;

  useEffect(() => {
    setTimeout(() => {
      if (isMobileOrTablet) {
        document.body.style.overflow = "hidden";
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
      }
      setShowKlaviyo(true);
    }, 10000);
  }, [isMobileOrTablet]);

  const eventLog = (eventName, eventData) => {
    let match = document.cookie.match(new RegExp('(^| )axeptio_authorized_vendors=([^;]+)'));
    if (match && match.length > 2) {
      let value = match[2];
      if (value.indexOf('google_analytics') > -1) {
        if (eventData !== '') {
          window.gtag('event', eventName.ga, {
            event_name: eventData,
           });
        } else {
          window.gtag('event', eventName.ga);
        }
        console.log('Google event sent: ' + eventName.ga + ', ' + eventData);
      }
      if (value.indexOf('facebook_pixel') > -1) {
        if (eventData !== '') {
          trackMetaPixelEvent(eventName.fb, {
            content_name: eventData
           });
        } else {
          trackMetaPixelEvent(eventName.fb);
        }
        console.log('Facebook event sent: ' + eventName.fb + ', ' + eventData);
      }
      if (value.indexOf('tiktok') > -1) {
        if (eventData !== '') {
          trackTikTokPixelEvent(eventName.tt, {
            event_name: eventData
           });
        } else {
          trackTikTokPixelEvent(eventName.tt);
        }
        console.log('Tiktok event sent: ' + eventName.tt + ', ' + eventData);
      }
    }
    // var consentResult = JSON.parse(localStorage.getItem('be_consent'));
    // if (consentResult && consentResult.analytics) {
    //   if (eventData !== '') {
    //     window.gtag('event', eventName.ga, {
    //       event_name: eventData,
    //      });
    //   } else {
    //     window.gtag('event', eventName.ga);
    //   }
    // }
    // if (consentResult && consentResult.advertising) {
    //   if (eventData !== '') {
    //     trackMetaPixelEvent(eventName.fb, {
    //       content_name: eventData
    //      });
    //    trackTikTokPixelEvent(eventName.tt, {
    //       event_name: eventData
    //      });
    //   } else {
    //     trackMetaPixelEvent(eventName.fb);
    //     trackTikTokPixelEvent(eventName.tt);
    //   }
    // }
  };

  // useEffect(() => {
  //   if (!showCookies) {
  //     eventLog({'ga': 'pageview', 'fb': 'PageView', 'tt': 'PageView'}, '');
  //   }
  // }, [showCookies]);

  // const handleOutsideClick = (event) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   if (
  //     ref.current && !ref.current.contains(event.target)
  //   ) {
  //     localStorage.setItem('be_consent', JSON.stringify({analytics: false, advertising: false}));
  //     setShowCookies(false);
  //   }
  // };

  // useEffect(() => {
  //   if (isMobileOrTablet && !showKlaviyo) {
  //     document.addEventListener('mousedown', handleOutsideClick);
  //     return () => {
  //       document.removeEventListener('mousedown', handleOutsideClick);
  //     };
  //   }
  // }, [isMobileOrTablet, showKlaviyo]);

  // useEffect(() => {
  //   const updateMarginBottom = () => {
  //     if (ref.current) {
  //       const consentHeight = ref.current.offsetHeight;
  //       setMarginBottom(consentHeight);
  //     } else {
  //       setMarginBottom(0);
  //     }
  //   };
  //   // Initial check
  //   updateMarginBottom();

  //   // Re-check on window resize or other conditions
  //   window.addEventListener('resize', updateMarginBottom);
  //   return () => {
  //     window.removeEventListener('resize', updateMarginBottom);
  //   };
  // }, [showCookies]);

  return (
    <div className="App" ref={mainRef}>
    {/* <div className="App" ref={mainRef} style={{ marginBottom: `${marginBottom}px` }}> */}
      <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.5.0/bluebird.core.js"></script>
      {/* <div className="lg-only layout-column center-center" style={{width: '100%', maxWidth: '1440px', padding: '74px', boxSizing: 'border-box'}}> */}
      <Header />
      {/* </div> */}
      {/* <div className="sm-only layout-column center-center">
        <Header layout="sm-only"/>
      </div> */}
    {/* { showCookies ? <React.StrictMode><CookieConsent showCookies={showCookies} setShowCookies={setShowCookies} height={height} setHeight={setHeight} ref={ref}/></React.StrictMode> : null } */}
    { showKlaviyo ? <KlaviyoPopup showKlaviyo={showKlaviyo} setShowKlaviyo={setShowKlaviyo} eventLog={eventLog}/> : null }
    <Routes>
      <Route path='/' element={<HomePage eventLog={eventLog}/>}/>
      <Route path='/cookie-policy' element={<CookiePage/>}/>
      <Route path='/privacy-policy' element={<PrivacyPage/>}/>
      <Route path='/terms-of-use' element={<TermsPage/>}/>
      <Route path='/accessibility-statement' element={<AccessibilityPage/>}/>
      <Route path='/todo' element={<Todo/>}/>
    </Routes>
    <Footer/>
    </div>
  );
}

export default App;
