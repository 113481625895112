import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import AppIcons from './AppIcons';
import '../App.css';

function HomePage(props) {
  // Media query to detect mobile or tablet
  const isMobileOrTablet = window.matchMedia("(max-width: 1024px)").matches;
  const allApps = useMemo(() => [], []);
  const categories = useMemo(() => [], []);
  const carrouselData = useMemo(() => [], []);
  let limit = 3;
    if (isMobileOrTablet) {
      limit = 1;
    }

  const [loading, setLoading] = useState(true);
  // const [chunks, setChunks] = useState({});

  useEffect(() => {
    // URL of the JSON file hosted on CloudFront
    const url = 'https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/be-apps-categories.json';
    const appsUrl = 'https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/be-apps.json';
    // Fetch the JSON file
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        data.forEach(function(cat) {
          categories.push(cat);
        });

        fetch(appsUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        // setData(data);
        setLoading(false);
        // const chunked = {};
        // let index = 0;
        // let counter = 0;

        // Divide the apps to category sets
        data.forEach(function(app) {
          allApps.push(app);
          if (app.lp_carrousel > 0) {
            carrouselData.push({
              'image': app.banner,
              'name': app.name,
              'link': `https://play.google.com/store/apps/details?id=${app.package_name}&referrer=utm_source%3Dlanding_page%26utm_medium%3Dlanding_page%26utm_campaign%3Dfrom_landing_page`
            })
          }
        });

        // Show all apps on load
        // while (index < data.length) {
        //   const chunk = data.slice(index, index + limit);
        //   chunked[counter] = chunk;
        //   index += limit;
        //   counter += 1;
        // }

        // setChunks(chunked);
      })
      .catch(error => {
        console.error('Fetching error:', error);
        setLoading(false);
      });
      })
      .catch(error => {
        console.error('Fetching error:', error);
        setLoading(false);
      });
  }, [limit, carrouselData, allApps, categories]);


  if (loading) {
    return <div></div>;
  }

  return (
    <div className="main">
      <div className="homepage layout-column center-center">
        <div className="headline layout-row center-left">
          <div className="titles layout-column left-center">
            <p className="title display">Beautiful apps for everyday use</p>
            <p className="title regular white sub-title">All the apps you ever needed are now in one place. Easy to use, and beautifully designed. Get all our apps in one single subscription.</p>
            <Link className="google-play" to="https://play.google.com/store/apps/dev?id=4642277398456551127" target="_blank">
              <img alt='' src="https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/google_play.png"/>
            </Link>
          </div>
          <img alt='' className="right-background lg-only" src="https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/header_background_desktop.jpg"/>
        </div>
        <div className='carrousel-container layout-column left-center'>
          <p className="title header-2 bold white icons-title layout-row">Featured apps</p>
          <div className='carrousel layout-row center-left'>
            {carrouselData.map((app, i) => (
              <Link key={i} className='app-banner' to={app.link} target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                props.eventLog({'ga': 'download', 'fb': 'ViewContent', 'tt': 'ClickToDownload'}, app.name.replaceAll(' ', '_').toLowerCase());
              }}>
                <img alt='' src={app.image}/>
              </Link>
            ))}
          </div>
        </div>
        <div className='all-icons layout-column center-center'>
          <p className="title header-2 bold white icons-title layout-row">Beautiful essentials apps</p>
          < AppIcons categories={categories} apps={allApps} eventLog={props.eventLog}/>
          {/* {Object.keys(chunks).map((i, chunk) => (
            <div key={i} className='app-icons layout-row center-center'>
              {chunks[i].map((app) => (
                < AppIcon key={app.name.replaceAll(' ', '')} app={app} eventLog={props.eventLog}/>
              ))}
            </div>
          ))} */}
        </div>
      </div>
      {/* <div className="homepage sm-only layout-column center-center">
        <div className="titles layout-column center-center">
          <img alt='' className="title" src="https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/title_mobile.png"/>
          <p className="title regular white sub-title">All the apps you ever needed are now in one place. No ads, easy to use, and beautifully designed. Get all our apps in one single subscription.</p>
          <Link className="google-play" to="https://play.google.com/store/apps/dev?id=4642277398456551127" target="_blank">
            <img alt='' src="https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/google_play.png"/>
          </Link>
        </div>
        <div className="line-separator-dashed"></div>
        <div className="all-apps">
        {Object.keys(chunks).map((i, chunk) => (
          <div key={i} className='app-icons layout-row center-center'>
            {chunks[i].map((app) => (
              < AppIcon key={app.name.replaceAll(' ', '')} app={app} eventLog={props.eventLog}/>
            ))}
          </div>
        ))}
        </div>
      </div> */}
    </div>
  );
}

export default HomePage;
