import '../App.css';

function CookiePage(props) {
  return (
    <div className="privacy layout-column center-center">
      <div className="privacy-terms">
      <div className="main-title">
        <h1 className="header-2 bold">BEAUTIFUL ESSENTIALS COOKIE POLICY</h1>
      </div>
      <div className="page-body">
        <div className="section">
          <p className="regular">
            This is the Cookie Policy of Photomyne Ltd.'s (“Company” or “We”) Beautiful Essentials Services. In this Cookie Policy you may learn more about the cookies we use and why we use them. You can choose not to allow some types of cookies.
          </p>
          <a href="javascript:openAxeptioCookies()" className="regular manage-cookies"
          // onClick={(e) => {
          //   e.stopPropagation();
          //   e.preventDefault();
          //   props.setShowCookies(true);
          // }}
          >
            Manage my cookies preferences
          </a>
        </div>
        <div className="section">
          <ol className="terms-list">
            <li>
              <h3 className="header-2 bold">
                What are cookies?
              </h3>
              <p className="regular">
               A cookie is a small piece of information which is sent to and stored on your browser or device. Cookies do not damage your browser or device. Most browsers or devices may allow you to block cookies, but you may not be able to use some features within our website if you block them.
              </p>
              <p className="regular">
                When referring to cookies we include first- and third-party cookies, tracking pixels and plug-ins, including technologies from third party publishers and other tracing technologies. Below you will find more detailed information about our cookies and the reason for using them.
              </p>
            </li>
            <li>
              <h3 className="header-2 bold">
                How to restrict or block cookies
              </h3>
              <p className="regular">
                Whether we collect some or all of this information often depends on what type of
                browser or device you're using and its settings. For example, different types of
                information are available depending on whether you're using a Mac or a PC, or an iPhone
                or Android phone. To learn more about what information your browser or device makes
                available to us, please also check the policies of your device manufacturer or browser or
                software provider.
              </p>
              <p className="regular">
                You can at any time disable non-essential cookies by withdrawing your consent by
                managing your cookie preferences in “Cookie Settings” at the bottom of this page. You
                can also set your browser not to accept cookies. However, please be aware that if you
                choose not to accept cookies or disable them, you may face limitation on functionality
                and a deteriorated user experience.
              </p>
            </li>
            <li>
              <h3 className="header-2 bold">
                What types of cookies do we use and why?
              </h3>
              <ol className="terms-list">
                <li className="regular">
                  <span className="bold">Strictly necessary cookies.</span> Some of the cookies are essential for the website to
                  function and to enable you to make use of it, such as Web portal (to allow us to
                  maintain and provide you with our services), Customer support (to allow us to
                  address your concerns via chat on our website) and Payment platforms (to allow
                  you a safe manner to pay for the services should you decide to do so).
                </li>
                <div className="table-wrapper">
                  <table className="parties four-columns">
                    <thead>
                      <tr>
                        <td>Cookie name</td>
                        <td>Service</td>
                        <td>Duration</td>
                        <td>Description</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        We currently have no Strictly necessary cookies.
                      </tr>
                    </tbody>
                  </table>
                </div>
                <li className="regular">
                  <span className="bold">Advertising cookies.</span> These cookies enable us to generate personalized
                  advertisements or content on third-party websites. These cookies may be set
                  through our website by our advertising partners or us. To be able to deliver
                  content that fits your interests on third party sites, our advertising partners, such
                  as advertising platforms and social networks, may use your interactions on our
                  website together with personal information you have provided to them. If you
                  choose not to accept advertising cookies, you will not necessarily receive less
                  advertising, just that the advertising you receive will be less targeted to your
                  preferences.
                </li>
                <div className="table-wrapper">
                  <table className="parties four-columns">
                    <thead>
                      <tr>
                        <td>Cookie name</td>
                        <td>Service</td>
                        <td>Duration</td>
                        <td>Description</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          _fbp
                        </td>
                        <td>
                          Beautiful Essentials
                        </td>
                        <td>
                          3 months
                        </td>
                        <td>
                          Facebook sets this cookie to display advertisements when either on Facebook or on a digital platform powered by Facebook advertising after visiting the website.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          _tt_enable_cookie
                        </td>
                        <td>
                          Beautiful Essentials
                        </td>
                        <td>
                          1 year
                        </td>
                        <td>
                          Tiktok set this cookie to collect data about behaviour and activities on the website and to measure the effectiveness of the advertising.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          _ttp
                        </td>
                        <td>
                          Beautiful Essentials
                        </td>
                        <td>
                          1 year
                        </td>
                        <td>
                          TikTok set this cookie to track and improve the performance of advertising campaigns, as well as to personalise the user experience.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <li className="regular">
                  For illustration by way of an example, our engagement with Facebook allows us to place advertising content onto Facebook that is relevant to you based on your browsing patterns on our website.
                </li>
                <li className="regular">
                  You should check the privacy settings of the app platforms (such as Facebook
                  and/or WhatsApp and/or any other social media network). We are not
                  responsible for the privacy practices of such third parties and/or app platforms.
                  We recommend you to carefully read the terms of use and privacy policies of
                  such third parties and/or app platforms before using their services and
                  connecting to the Services.
                </li>
                <li className="regular">
                  <span className="bold">Performance and Analytics Cookies.</span> We use analytical cookies to gather
                  information about how visitors use our website. These cookies help us
                  understand which pages are visited most frequently, how users navigate through
                  our website, and if they encounter any error messages. The data collected by
                  these cookies is used solely for the purpose of improving our website's
                  performance and user experience. By allowing these cookies, you help us
                  enhance our services and provide you with a better browsing experience. If you
                  choose to disable analytical cookies, we may not be able to gather valuable
                  insights to improve our website's functionality.
                </li>
                <div className="table-wrapper">
                  <table className="parties four-columns">
                    <thead>
                      <tr>
                        <td>Cookie name</td>
                        <td>Service</td>
                        <td>Duration</td>
                        <td>Description</td>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        __ga
                      </td>
                      <td>
                        Google
                      </td>
                      <td>
                        13 months
                      </td>
                      <td>
                        Provides ad delivery or retargeting.
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </ol>
            </li>
            <li>
              <h3 className="header-2 bold">
                Manage your cookies preferences
              </h3>
              <p className="regular">
                Unless you set your cookie preference, we will continue to show you a cookie banner until you make your choice. We will then save your chosen preference to keep track of your choice. This cookie does not expire.
              </p>
              <p className="regular">
                You can always decide to withdraw your consent, change your preference or consent again at any time.
              </p>
              <a href="javascript:openAxeptioCookies()" className="regular manage-cookies"
              // onClick={(e) => {
              //   e.stopPropagation();
              //   e.preventDefault();
              //   props.setShowCookies(true);
              // }}
              >
                Manage my cookies preferences
              </a>
            </li>
            <li>
              <h3 className="header-2 bold">
                Changes to the Cookie policy
              </h3>
              <p className="regular">
                We may update this Cookie Policy from time to time. If we make significant changes, we will let you know, but please do check regularly this policy to ensure you are aware of the most updated version.
              </p>
            </li>
          </ol>
        </div>
        <div className="regular section updated-on">
          Last updated: May 28, 2024
        </div>
      </div>
    </div>
  </div>
  );
}

export default CookiePage;
